import { PaymentContext } from "../../../provider/Payment";
import { FC, useCallback, useContext } from "react";
import { PlanTypes, Signature as SignatureType } from "../../../types/plan.types";
import { Col, Row } from "../../core/Container";
import { Signature } from "./Signature/Signature";

interface LeftColContentProps {
  plan: PlanTypes;
}

const LeftColContent: FC<LeftColContentProps> = ({ plan }) => {
    const { signature, setSignature, onPaymentStartStep2 } = useContext(PaymentContext);
    
    const continuePayment = useCallback(async () => {
        setSignature({ ...signature, signed: true } as SignatureType);
        await onPaymentStartStep2();
    }, [onPaymentStartStep2, setSignature, signature]);

    return (
      <Col id="LeftColCheckout" className=" items-center w-full h-full xl:px-24 md:px-12 px-2">
        <h1 className="font-bold text-3xl text-center">{plan?.product.name}</h1>
        {
          plan && signature && !signature.iframe && !signature.signed ?
          (
            <Signature signature={signature} onSuccess={continuePayment} />
          )
          :
          (
            <>
              <img
                alt={plan?.product.name}
                src={plan?.product?.image?.url ?? ""}
                width={1000}
                height={1000}
                className="xl:w-3/4 w-full h-fit  my-4"
              />
              <Row className="">
                <div
                  dangerouslySetInnerHTML={{ __html: plan.product.description }}
                  className="flex flex-col item-description"
                />
              </Row>
            </>
          )
        }
      </Col>
    );
};
  

export default LeftColContent;
