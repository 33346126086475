import { useContext, useMemo } from "react";
import { PaymentContext } from "../../../../provider/Payment";
import { NeedsForm as Form} from "./form";

export const NeedsForm = () => {
    const { price } = useContext(PaymentContext);

    const hasNeedsForm = useMemo(() => (price?.product?.hasOwnProperty('has_checkout_questionnary') && price?.product?.has_checkout_questionnary) || false, [price]);

    return (
        <>
            {
                hasNeedsForm && (
                    <>
                    <div className="w-full h-[2px] bg-[#DEE2E6] mb-4 hidden"></div>
                    <div className="w-full">
                        <Form />
                    </div>
                    </>
                )
            }
        </>
    );
};