import { FC, useContext, useEffect, useMemo } from "react";

import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useAuthState } from "react-firebase-hooks/auth";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import {
  applyCoupon,
  getBillingInfo,
} from "../../../../services/apis/checkout/plans";
import { Col, Row } from "../../../core/Container";
import { Spinner } from "../../../core/Loading";
import { auth } from "../../../../services/firebase/auth/auth";
import { GTMEvents } from "../../../../utils/constants/googleTagManager";
import { CountrySelector } from "./CountrySelector";
import { PaymentContext } from "../../../../provider/Payment";
import { PaymentErrors } from "../PaymentErrors";

const Form: FC<{ currentStep: number; setCurrentStep: (step: number) => void }> = ({ currentStep, setCurrentStep }) => {
  const {
    price, setPrice
    , signature
    , plan, loading
    , coupon, setCoupon
    , firstName, setFirstName
    , lastName, setLastName
    , company, setCompany
    , address, setAddress
    , zip, setZip
    , city, setCity
    , country, setCountry
    , showBillingInfo, setShowBillingInfo
    , loadingCoupon, setLoadingCoupon
    , startElectronicSignatureStep1
    , onPaymentStart,
    formErrors
  } = useContext(PaymentContext);

  const { t } = useTranslation();
  const [user, authLoading] = useAuthState(auth);
  const elements = useElements();
  const stripe = useStripe();

  useEffect(() => {
    if (formErrors) {
      const errorFields = ['address', 'city', 'firstName', 'lastName', 'zip'];

      if (errorFields.some(field => formErrors[field])) {
        setCurrentStep(2);
        setShowBillingInfo(true);
      }
    }
  }, [formErrors, setShowBillingInfo, setCurrentStep]);

  useEffect(() => {
    let amount = (price?.amount || 0)/100;
    if (price?.recurring !== 'none') {
      amount = amount * (price?.iteration || 1);
    }
    // sending "checkout" event to GTM as soon as we load the strip form
    window.dataLayer.push({
      event: GTMEvents.checkout,
      ecommerce: {
        value: amount,
        currency: price?.currency,
        coupon: coupon?.coupon?.stripe_id,
        user_id: user?.uid,
        email: user?.email || '',
        items: [{
          item_name: price?.description, // Name or ID is required.
          item_id: price?.id,
          price: amount,
          coupon: coupon?.coupon?.stripe_id,
          discount: 0,
          item_brand: "Greenbull Campus",
          quantity: 1
        }]
      }
    });
    if (price?.currency) {
      getBillingInfo(price?.currency).then(({ data }) => {
        setFirstName(data?.user?.firstname);
        setLastName(data?.user?.lastname);
        setCompany(data?.company);
        setAddress(data?.line1);
        setZip(data?.postale_code);
        setCity(data?.city);
        setCountry(data?.country);
      });
    }
  }, [coupon?.coupon?.stripe_id, price?.amount, price?.currency, price?.description, price?.id, price?.iteration, price?.recurring, setAddress, setCity, setCompany, setCountry, setFirstName, setLastName, setZip, user?.email, user?.uid]);

  useEffect(() => {
    const paymentElement = elements?.getElement(PaymentElement);
    const handlePaymentInfoChange = (event: any) => {
      if (event?.elementType === 'payment' && event?.complete) {
        let amount = (price?.amount || 0)/100;
        if (price?.recurring !== 'none') {
          amount = amount * (price?.iteration || 1);
        }
        window.dataLayer.push({
          event: GTMEvents.paymentInfo,
          ecommerce: {
            value: amount,
            currency: price?.currency,
            coupon: coupon?.coupon?.stripe_id,
            payment_type: event?.value?.type || 'card',
            user_id: user?.uid,
            email: user?.email || '',
            items: [{
              item_name: price?.description,
              item_id: price?.id,
              price: amount,
              coupon: coupon?.coupon?.stripe_id,
              discount: 0,
              item_brand: "Greenbull Campus",
              quantity: 1
            }]
          }
        });
      }
    };
    
    if (paymentElement) {
      (paymentElement as any).off('change');
      (paymentElement as any).on('change', handlePaymentInfoChange);
      return () => {
        (paymentElement as any).off('change', handlePaymentInfoChange);
      };
    }
  }, [coupon?.coupon?.stripe_id, elements, price, user?.email, user?.uid]);

  const hasSignature = useMemo(() => plan?.signature?.enabled || false, [plan]);

  const paymentHintText = useMemo(() => {
    if (price?.recurring === "none") {
      return t("oneTime");
    } else if (price?.recurring === "month" && price?.iteration > 1) {
      return t("split", {
        price: `${price?.amount / 100}${price?.currency === "usd" ? "$" : "€"}`,
        count: price.iteration,
      });
    } else if (price?.recurring === "month") {
      return t("perMonth", {
        price: `${price?.amount / 100}${price?.currency === "usd" ? "$" : "€"}`,
      });
    } else {
      return `${((price?.amount || 0) / 100 / 365).toFixed(2)}${
        price?.currency === "usd" ? "$" : "€"
      } ${t("day")}`;
    }
  }, [price?.amount, price?.currency, price?.iteration, price?.recurring, t]);

  return (
    <div className="relative w-full p-[8px] sm:p-[20px] self-center gap-2 mt-10 overflow-y-auto overflow-x-hidden">
      <PaymentErrors fullPage={false} setCurrentStep={setCurrentStep}>
        <>
          {
              currentStep === 2 && (
              <>
                <p className="text-[#71BD22] font-semibold text-2xl md:text-3xl mb-[8px] sm:mb-[20px]">&Eacute;tape 2</p>
                <h3 className="font-bold text-2xl md:text-3xl mb-6">Paiement en ligne</h3>
                <div className="w-full flex flex-col gap-2 relative">
                  <div
                    className="w-full rounded-lg p-4"
                    style={{
                      border: "2px solid #DEE2E6",
                    }}
                  >
                    {
                      (loading || (signature && !signature.iframe && !signature.signed) ) && (
                        <div style={{
                          position: 'absolute',
                          backgroundColor: '#8c8c8c82',
                          zIndex: 999,
                          width: '100%',
                          height: '100%',
                          left: 0,
                          top: 0,
                          borderRadius: '0.5rem',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}>
                          <div className="loading-main-button" />
                              <p className="m-2 text-white">{ signature && !signature.iframe && !signature.signed ? 'Signature en cours...' : 'Traitement en cours...' }</p>
                        </div>
                      )     
                    }
                    
                    <p className="text-base font-semibold mb-2">{t("summary")}</p>
                    <Row className="w-full gap-8">
                      <p className="text-sm font-medium flex-1">
                        {price?.product.name}
                      </p>
                      <p className="text-sm font-semibold flex gap-2">
                        {price?.amount_initial && (
                          <span className="line-through text-gray-500">
                            {price?.amount_initial / 100}
                            {price.currency === "usd" ? "$" : "€"}
                          </span>
                        )}
                        {(price?.amount || 0) / 100}
                        {price?.currency === "usd" ? "$" : "€"}
                      </p>
                    </Row>
                    <p className="flex-1 font-normal text-xs mt-1 text-[#5C5F66]">
                      {paymentHintText}
                    </p>
                    <div className="w-full h-[2px] bg-[#DEE2E6] my-4" />
                    {plan && plan?.prices.length > 1 && (
                      <>
                        <Row className="items-center gap-2">
                          <p className="text-base font-semibold flex-1">
                            {t("paymentPlan")}
                          </p>
                          <Row></Row>
                          {plan?.prices.map((item, index) => {
                            const isActive = item.price.id === price?.id;
                            return (
                              <button
                                key={index}
                                className={clsx(
                                  { "border-green-500 text-green-500": isActive },
                                  "rounded-md border-[1px] border-gray-200 border-solid w-[40px] py-1 font-semibold"
                                )}
                                onClick={() => {
                                  setPrice(item.price);
                                  setCoupon(undefined);
                                  const input: any =
                                    document.getElementById("coupon");
                                  if (input) input.value = "";
                                }}
                              >
                                <p>x{item.price.iteration ?? "1"}</p>
                              </button>
                            );
                          })}
                        </Row>
                        <div className="w-full h-[2px] bg-[#DEE2E6] my-4" />
                      </>
                    )}
                    {plan?.has_coupon && (
                      <Row
                        className={clsx(
                          {
                            "border-green-500": coupon?.valid,
                            "border-red-500 text-red-500": coupon?.valid === false,
                          },
                          "items-center h-[44px] bg-[#fbfbfb] px-3 border-[1px] rounded-md border-solid border-[#ccc] font-medium"
                        )}
                      >
                        <input
                          placeholder={t("coupon")}
                          className={clsx(
                            { "placeholder:text-red-500": coupon?.valid === false },
                            "w-full bg-[#fbfbfb] h-full text-sm"
                          )}
                          id="coupon"
                          onChange={() => setCoupon(undefined)}
                        />

                        <button
                          disabled={loadingCoupon}
                          className="text-xs font-semibold"
                          onClick={async () => {
                            const couponInp: any = document.getElementById("coupon");
                            if (coupon?.valid) {
                              setCoupon(undefined);
                              couponInp.value = "";
                            } else if (user) {
                              const token = await user.getIdToken();
                              const couponVal = couponInp.value;
                              if (couponVal.trim() === "" || !couponVal) {
                                setCoupon({
                                  valid: false,
                                  type: "",
                                  message: t("enterCoupon"),
                                  coupon: { id: 0, currency: '', created_at: '', stripe_id: '', updated_at: ''},
                                });
                              } else {
                                setLoadingCoupon(true);
                                applyCoupon({
                                  coupon: couponVal,
                                  userToken: token,
                                  productId: price?.product?.id || 0,
                                  currency: price?.currency || 'eur',
                                })
                                  .then((response) => {
                                    setCoupon(response.data);
                                  })
                                  .finally(() => {
                                    setLoadingCoupon(false);
                                  });
                              }
                            }
                          }}
                        >
                          {loadingCoupon ? (
                            <Spinner />
                          ) : (
                            t(coupon?.valid ? "remove" : "apply")
                          )}
                        </button>
                      </Row>
                    )}
                    {!coupon?.valid && (
                      <p className="text-xs my-1 text-red-500">{coupon?.message}</p>
                    )}

                    {coupon?.valid && (
                      <Row className="w-full items-center justify-center p-2">
                        <p className="text-sm font-medium flex-1">{t("subTotal")}</p>
                        <p className="text-sm font-semibold">
                          {(price?.amount || 0) / 100}
                          {price?.currency === "usd" ? "$" : "€"}
                        </p>
                      </Row>
                    )}
                    {coupon?.valid && (
                      <Row className="w-full items-center justify-center bg-green-100 p-2 rounded-md mb-2 border-[1px] border-green-600 border-solid">
                        <p className="text-sm font-medium flex-1 text-green-600">
                          {t("discount")}{" "}
                          {coupon?.valid &&
                            coupon.coupon.percent_off &&
                            "-" + (coupon.coupon.percent_off + "%")}
                        </p>
                        <p className="text-sm font-semibold text-green-600">
                          -
                          {coupon?.valid
                            ? coupon.coupon.percent_off
                              ? Math.round(
                                  ((price?.amount || 0) / 100) *
                                    (coupon.coupon.percent_off / 100)
                                )
                              : (coupon.coupon.amount_off ?? 0) / 100
                            : (price?.amount || 0) / 100}
                          {price?.currency === "usd" ? "$" : "€"}
                        </p>
                      </Row>
                    )}
                    <Row
                      className={clsx(
                        { "mt-4": !coupon?.valid },
                        "w-full items-center justify-center bg-gray-100 p-2 rounded-md"
                      )}
                    >
                      <p className="text-sm font-bold flex-1">{t("total")}</p>
                      <p className="text-sm font-bold">
                        {coupon?.valid
                          ? coupon.coupon.percent_off
                            ? Math.round(
                                ((price?.amount || 0) / 100) *
                                  (1 - coupon.coupon.percent_off / 100)
                              )
                            : ((price?.amount || 0) - (coupon.coupon.amount_off ?? 0)) / 100
                          : (price?.amount || 0) / 100}
                        {price?.currency === "usd" ? "$" : "€"}
                      </p>
                    </Row>
                    <div className="w-full h-[2px] bg-[#DEE2E6] mt-4" />
                    <button
                      className="w-full"
                      onClick={() => setShowBillingInfo(!showBillingInfo)}
                    >
                      <Row className="items-center justify-between">
                        <p className="text-base font-semibold my-4">
                          {t("billingInfo")}
                        </p>

                        <span className={showBillingInfo ? "rotate-180" : ""}>
                          <svg
                            width="10"
                            height="7"
                            viewBox="0 0 10 7"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M0.305299 1.06299C0.492826 0.875518 0.747134 0.770203 1.0123 0.770203C1.27746 0.770203 1.53177 0.875518 1.7193 1.06299L5.0123 4.35599L8.3053 1.06299C8.4939 0.880831 8.7465 0.780037 9.0087 0.782315C9.2709 0.784593 9.52171 0.889762 9.70712 1.07517C9.89252 1.26058 9.99769 1.51139 9.99997 1.77359C10.0023 2.03578 9.90146 2.28839 9.7193 2.47699L5.7193 6.47699C5.53177 6.66446 5.27746 6.76978 5.0123 6.76978C4.74713 6.76978 4.49283 6.66446 4.3053 6.47699L0.305299 2.47699C0.117828 2.28946 0.012512 2.03515 0.012512 1.76999C0.012512 1.50482 0.117828 1.25052 0.305299 1.06299Z"
                              fill="black"
                            />
                          </svg>
                        </span>
                      </Row>
                    </button>

                    {showBillingInfo && (
                      <Col className="items-center w-full gap-2">
                        <Row className="w-full gap-2">
                          <Col className="w-1/2">
                            <input
                              placeholder={t("billing.fname")}
                              className="text-sm w-full h-[44px] bg-[#fbfbfb] px-3 border-[1px] rounded-md border-solid border-[#ccc] font-medium"
                              value={firstName}
                              onChange={(e) => setFirstName(e.target.value)}
                            />
                            {(formErrors?.firstName) && (
                              <p className="text-xs my-1 text-red-500">
                                {t("required", { item: formErrors?.firstName || "Le champ Prénom est" })}
                              </p>
                            )}
                          </Col>
                          <Col className="w-1/2">
                            <input
                              placeholder={t("billing.lname")}
                              className="text-sm w-full h-[44px] bg-[#fbfbfb] px-3 border-[1px] rounded-md border-solid border-[#ccc] font-medium"
                              value={lastName}
                              onChange={(e) => setLastName(e.target.value)}
                            />
                            {(lastName?.trim() == "" || formErrors?.lastName) && (
                              <p className="text-xs my-1 text-red-500">
                                {t("required", { item: formErrors?.lastName || "Le champ Nom est" })}
                              </p>
                            )}
                          </Col>
                        </Row>
                        <Col className="w-full">
                          <input
                            placeholder={t("billing.company")}
                            className="text-sm w-full h-[44px] bg-[#fbfbfb] px-3 border-[1px] rounded-md border-solid border-[#ccc] font-medium"
                            value={company}
                            onChange={(e) => setCompany(e.target.value)}
                          />
                        </Col>
                        <Col className="w-full">
                          <input
                            placeholder={t("billing.address")}
                            className="text-sm w-full h-[44px] bg-[#fbfbfb] px-3 border-[1px] rounded-md border-solid border-[#ccc] font-medium"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                          />
                          {(formErrors?.address) && (
                            <p className="text-xs my-1 text-red-500">
                              {t("required", { item: formErrors?.address || "Le champ Adresse est" })}
                            </p>
                          )}
                        </Col>
                        <Row className="w-full gap-2">
                          <Col className="w-1/2">
                            <input
                              placeholder={t("billing.zip")}
                              className="text-sm w-full h-[44px] bg-[#fbfbfb] px-3 border-[1px] rounded-md border-solid border-[#ccc] font-medium"
                              value={zip}
                              onChange={(e) => setZip(e.target.value)}
                            />
                            {(formErrors?.zip) && (
                              <p className="text-xs my-1 text-red-500">
                                {t("required", { item: formErrors?.zip || "Le champ Code postal est" })}
                              </p>
                            )}
                          </Col>
                          <Col className="w-1/2">
                            <input
                              placeholder={t("billing.city")}
                              className="text-sm w-full h-[44px] bg-[#fbfbfb] px-3 border-[1px] rounded-md border-solid border-[#ccc] font-medium"
                              value={city}
                              onChange={(e) => setCity(e.target.value)}
                            />
                            {(formErrors?.city) && (
                              <p className="text-xs my-1 text-red-500">
                                {t("required", { item: formErrors?.city || "Le champ Ville est" })}
                              </p>
                            )}
                          </Col>
                        </Row>
                        <CountrySelector
                          selectedCountry={country}
                          setSelectedCountry={setCountry}
                        />
                      </Col>
                    )}

                    <p className="text-base font-semibold my-4">{t("method")}</p>
                    <PaymentElement
                      options={{
                        layout: {
                          type: "accordion",
                          defaultCollapsed: true,
                          radios: true,
                          spacedAccordionItems: true,
                        },
                        fields: {
                          billingDetails: {
                            name: "never",
                            address: "never",
                            email: "never",
                            phone: "never",
                          }
                        }
                      }}
                      />
                  </div>
                </div>
              </>
            )
          }
          
          {
            hasSignature && (!signature || (signature && !signature.signed)) ? 
              (
                <>
                  {
                    currentStep === 2 && 
                      (
                        <>
                          <button
                            className="w-full h-[50px] rounded-xl green-gradient-button-bg font-medium mt-8 mb-1 text-white "
                            disabled={
                              loading || !stripe || !elements || authLoading || !user?.uid
                            }
                                onClick={() => { startElectronicSignatureStep1().then((response) => { if (response) setCurrentStep(3); } ) }}
                          >
                            {loading || (signature && !signature.signed) ? <Spinner /> : "Confirmer"}
                          </button>
                        </>
                      )     
                  }
                </>
              )
              :
              (
                <button
                  className="w-full h-[50px] rounded-xl green-gradient-button-bg font-medium my-8 text-white"
                  disabled={
                    loading || !stripe || !elements || authLoading || !user?.uid
                  }
                  onClick={onPaymentStart}
                >
                  {loading ? <Spinner /> : "Confirmer"}
                </button>
              )
          }
        </>
      </PaymentErrors>
    </div>
  );
};

export default Form;
