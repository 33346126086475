import { FC, useContext, useCallback } from "react";
import { PaymentContext } from "../../../provider/Payment";
import { Signature as SignatureType } from "../../../types/plan.types";
import { Signature } from "./Signature/Signature";

export const SignatureStep: FC<{ currentStep: number; setCurrentStep: (step: number) => void }> = ({ currentStep, setCurrentStep }) => {
    const { signature, setSignature, onPaymentStartStep2 } = useContext(PaymentContext);
    
    const continuePayment = useCallback(async () => {
        setCurrentStep(2);
        setSignature({ ...signature, signed: true } as SignatureType);
        window.document.getElementById('top_payment_process')?.scrollIntoView();
        await onPaymentStartStep2();
    }, [onPaymentStartStep2, setSignature, signature, setCurrentStep]);
    
    return (
        <>
        {
            signature && !signature.iframe && !signature.signed &&
            (
                <Signature signature={signature} onSuccess={continuePayment} />
            )
        }
        </>
    );
};