import React, { Context, createContext, FC, ReactNode, useCallback, useMemo, useState, useEffect } from "react";
import {
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../services/firebase/auth/auth";
import {
  createSubscription,
  getPaymentIntent,
  generateElectronicSignature,
} from "../services/apis/checkout/plans";
import { PriceTypes, PlanTypes, Signature as SignatureType } from "../types/plan.types";
import { couponType } from "../utils/constants/plans";
import { GTMEvents } from "../utils/constants/googleTagManager";
import { mixpanelCheckout } from "../utils/tracker";
import { useParams } from "react-router-dom";
import { NeedType } from "../utils/constants/need";

type PaymentContextType = {
  loading: boolean;
  setLoading: (value: boolean) => void;
  onPaymentStart: () => void;
  startElectronicSignature: (value: boolean) => void;
  startElectronicSignatureStep1: () => Promise<any>;
  startElectronicSignatureStep2: (value: boolean) => Promise<boolean>;
  onPaymentStartStep2: (defaultPaymentId?: string, defaultPaymentType?: string) => void;
  onPaymentStartStep1: () => void;
  totalPrice: number;
  paymentSuccess: boolean;
  setPaymentSuccess: (value: boolean) => void;
  paymentFailed: boolean;
  setPaymentFailed: (value: boolean) => void;
  formErrors: any;
  setFormErrors: (value: any) => void;
  paymentError?: string;
  setPaymentError: (value?: string) => void;
  firstName?: string;
  setFirstName: (value?: string) => void;
  lastName?: string;
  setLastName: (value?: string) => void;
  company?: string;
  setCompany: (value?: string) => void;
  address?: string;
  setAddress: (value?: string) => void;
  zip?: string;
  setZip: (value?: string) => void;
  city?: string;
  setCity: (value?: string) => void;
  country?: string | { label: string; value: string };
  setCountry: (value: string | { label: string; value: string }) => void;
  paymentId: string;
  setPaymentId: (value: string) => void;
  paymentType: string;
  setPaymentType: (value: string) => void;
  giveUp: boolean;
  setGiveUp: (value: boolean) => void;
  showBillingInfo: boolean;
  setShowBillingInfo: (value: boolean) => void;
  loadingCoupon: boolean;
  setLoadingCoupon: (value: boolean) => void;
  signature: SignatureType | null;
  setSignature: (value: SignatureType | null) => void;
  nextStepPayment: boolean;
  setNextStepPayment: (value: boolean) => void;
  plan?: PlanTypes;
  price?: PriceTypes;
  setPrice: (value: PriceTypes) => void;
  coupon?: couponType;
  setCoupon: (value?: couponType) => void;
  need?: NeedType;
  setNeed: (value: NeedType) => void;
  reset: () => void;
};

interface PaymentProviderProps {
  children: ReactNode;
  plan: PlanTypes;
  defaultPrice: PriceTypes;
  changeDefaultPrice: (value: PriceTypes) => void;
}

export const PaymentContext: Context<PaymentContextType> = createContext<PaymentContextType>(
  {} as PaymentContextType
);

export const PaymentProvider: FC<PaymentProviderProps> = ({ children, plan, defaultPrice, changeDefaultPrice }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [paymentSuccess, setPaymentSuccess] = useState<boolean>(false);
  const [paymentFailed, setPaymentFailed] = useState<boolean>(false);
  const [paymentError, setPaymentError] = useState<string | undefined>("somethingWrong");
  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [company, setCompany] = useState<string>();
  const [address, setAddress] = useState<string>();
  const [zip, setZip] = useState<string>();
  const [city, setCity] = useState<string>();
  const [country, setCountry] = useState<string | { label: string; value: string }>();
  const [user, authLoading] = useAuthState(auth);
  const [paymentId, setPaymentId] = useState<string>('');
  const [paymentType, setPaymentType] = useState<string>('');
  const [giveUp, setGiveUp] = useState<boolean>(true);
  const [showBillingInfo, setShowBillingInfo] = useState<boolean>(true);
  const [loadingCoupon, setLoadingCoupon] = useState<boolean>(false);
  const [signature, setSignature] = useState<SignatureType | null>(null);
  const [nextStepPayment, setNextStepPayment] = useState<boolean>(false);
  const [price, setPrice] = useState<PriceTypes>(defaultPrice);
  const [coupon, setCoupon] = useState<couponType>();
  const elements = useElements();
  const stripe = useStripe();
  const { slug } = useParams();
  const courseIdForSignature = useMemo(() => plan?.signature?.courseId || null, [plan]);
  const [need, setNeed] = useState<NeedType>({
        attentes: '',
        projet: '',
        experience: '',
        situationpro: '',
        poste: '',
        amenagement: '',
        autres: ''
  });
  const [formErrors, setFormErrors] = useState<any>(null);
  
  useEffect(() => {
    changeDefaultPrice(price);
  }, [price, changeDefaultPrice]);

  const reset = useCallback(() => {
      setPaymentSuccess(false);
      setPaymentFailed(false);
      setPaymentError(undefined);
      setSignature(null);
      setPaymentId('');
      setPaymentType('');
  }, []);

  const totalPrice = useMemo(() => {
    let total = coupon?.valid
      ? coupon.coupon.percent_off
        ? Math.round((price?.amount ?? 1) * (1 - (coupon.coupon.percent_off / 100)))
        : (price?.amount ?? 0) - (coupon.coupon.amount_off ?? 0)
      : price?.amount ?? 0;

    if (price?.recurring !== "none" && price?.iteration > 0) {
      total = price.iteration * total;
    }

    return total;
  }, [coupon, price?.amount, price?.recurring, price?.iteration]);
  
  const handlePaymentThatRequiresCustomerAction = useCallback(
    async ({ subscription, invoice, priceId, paymentMethodId }: any) => {
      if (subscription && subscription.error) {
        throw subscription.error;
      }

      if (subscription && subscription.status === "active") {
        return { subscription, priceId, paymentMethodId };
      }

      const paymentIntent = invoice
        ? invoice.payment_intent
        : getPaymentIntent(subscription);

      if (
        paymentIntent &&
        (paymentIntent.status === "requires_action" ||
          paymentIntent.status === "requires_confirmation")
      ) {
        return stripe
          ?.confirmCardPayment(paymentIntent.client_secret, {
            payment_method: paymentMethodId,
            setup_future_usage: "off_session",
          })
          .then((result: any) => {
            if (result.error) {
              throw result;
            } else {
              if (result.paymentIntent.status === "succeeded") {
                return {
                  priceId: priceId,
                  subscription: subscription,
                  invoice: invoice,
                  paymentMethodId: paymentMethodId,
                };
              }
            }
          });
      } else {
        return { subscription, priceId, paymentMethodId };
      }
    },
    [stripe]
  );

  const handlePaymentSEPAThatRequiresCustomerAction = useCallback(
    async ({ subscription, invoice, priceId, paymentMethodId }: any) => {
      if (subscription && subscription.error) {
        throw subscription.error;
      }

      if (subscription && subscription.status === "active") {
        return { subscription, priceId, paymentMethodId };
      }

      const paymentIntent = invoice
        ? invoice.payment_intent
        : getPaymentIntent(subscription);

      if (
        paymentIntent &&
        (paymentIntent.status === "requires_action" ||
          paymentIntent.status === "requires_confirmation")
      ) {
        return stripe
          ?.confirmSepaDebitPayment(paymentIntent.client_secret, {
            payment_method: paymentMethodId,
            setup_future_usage: "off_session",
          })
          .then((result: any) => {
            if (result.error) {
              throw result;
            } else {
              if (
                result.paymentIntent.status === 'succeeded' ||
                (result.object === 'payment_intent' && result.status === 'succeeded') ||
                (result.object === 'payment_intent' && result.status === 'processing')
              ) {
                return {
                  priceId: priceId,
                  subscription: subscription,
                  invoice: invoice,
                  paymentMethodId: paymentMethodId,
                };
              }
            }
          });
      } else {
        return { subscription, priceId, paymentMethodId };
      }
    },
    [stripe]
  );

  const handleRequiresPaymentMethod = useCallback(
    (result: any) => {
      const { subscription, paymentMethodId, priceId, invoice } = result || {};
      if ((subscription && subscription.status === "active") || invoice) {
        return { subscription, priceId, paymentMethodId };
      } else if (subscription?.latest_invoice?.payment_intent?.status === "requires_payment_method") {
        throw new Error("Your card was declined.");
      } else {
        return { subscription, priceId, paymentMethodId };
      }
    },
    []
  );

  const onSubscriptionComplete = useCallback(
    async (result: any) => {
      if (result) {
        let amount = (price?.amount || 0)/100;
        if (price?.recurring !== 'none') {
          amount = amount * (price?.iteration || 1);
        }
        window.dataLayer.push({
          event: GTMEvents.purchase,
          ecommerce: {
            value: amount,
            currency: price?.currency,
            coupon: coupon?.coupon?.stripe_id,
            payment_type: result?.type,
            user_id: user?.uid,
            email: user?.email || '',
            items: [{
              item_name: price?.description,
              item_id: price?.id,
              price: amount,
              coupon: coupon?.coupon?.stripe_id,
              discount: 0,
              item_brand: "Greenbull Campus",
              quantity: 1
            }]
          }
        });
        setPaymentSuccess(true);

        if (user && !authLoading) {
          const token = await user.getIdToken();
          mixpanelCheckout(user.uid, "success", token, price?.stripe_id || '');
        }
      }
    },
    [price?.amount, price?.recurring, price?.currency, price?.description, price?.id, price?.iteration, price?.stripe_id, coupon?.coupon?.stripe_id, user, authLoading]
  );

  const hasNeedsForm = useMemo(() => price?.product?.hasOwnProperty('has_checkout_questionnary') || false, [price]);

  const validateBillingFields = (billing: { firstName?: string; lastName?: string; city?: string; zip?: string; address?: string }) => {
    const errors: Record<string, string> = {};

    // Validation for billing information
    if (!billing.firstName?.trim()) errors.firstName = "Le champ Prénom est";
    if (!billing.lastName?.trim()) errors.lastName = "Le champ Nom est";
    if (!billing.city?.trim()) errors.city = "Le champ Ville est";
    if (!billing.zip?.trim()) errors.zip = "Le champ Code postal est";
    if (!billing.address?.trim()) errors.address = "Le champ Adresse est";
    return Object.keys(errors).length === 0 ? null : errors;
  };
  
  const validateFields = useCallback((billing: { firstName?: string; lastName?: string; city?: string; zip?: string; address?: string }, need: NeedType) => {
    const errors: Record<string, string> = {};

    // Validation for billing information
    if (!billing.firstName?.trim()) errors.firstName = "Le champ Prénom est";
    if (!billing.lastName?.trim()) errors.lastName = "Le champ Nom est";
    if (!billing.city?.trim()) errors.city = "Le champ Ville est";
    if (!billing.zip?.trim()) errors.zip = "Le champ Code postal est";
    if (!billing.address?.trim()) errors.address = "Le champ Adresse est";

    if (hasNeedsForm) {
      // Validation for need information
      if (!need.attentes.trim()) errors.attentes = "Ce champ est";
      if (!need.projet.trim()) errors.projet = "Ce champ est";
      if (!need.experience.trim()) errors.experience = "Ce champ est";
      if (!need.situationpro.trim()) errors.situationpro = "Ce champ est";
      if (!need.poste.trim()) errors.poste = "Ce champ est";
      if (!need.amenagement.trim()) errors.amenagement = "Ce champ est";
    }

    return Object.keys(errors).length === 0 ? null : errors;
  }, [hasNeedsForm]);

  const onPaymentStartStep1 = useCallback(async () => {
    if (!stripe || !elements) {
      throw "Stripe elements error";
    }
    
    const { error: submitError } = await elements.submit();
    if (submitError) {
      throw submitError;
    }
    
    return await stripe
      ?.createPaymentMethod({
        elements,
        params: {
          billing_details: {
            name: (firstName ?? "") + " " + (lastName ?? ""),
            address: {
              city: city,
              country: typeof country === 'object' ? country.value : country,
              postal_code: zip,
              state: city,
              line1: address,
              line2: company || '-',
            },
            email: auth.currentUser?.email ?? "",
            phone: auth.currentUser?.phoneNumber ?? "",
          },
        },
      })
      .then((result) => {
        if (result.paymentMethod) {
          const { id, type } = result.paymentMethod;
          setPaymentId(id);
          setPaymentType(type);
          return { paymentId: id, paymentType: type}
        }
        if (result.error) {
          throw result.error;
        }
      });
  }, [address, city, company, country, elements, firstName, lastName, stripe, zip]);

  const onPaymentStartStep2 = useCallback(async (defaultPaymentId?: string, defaultPaymentType?: string) => { 
    setLoading(true);
    await createSubscription(
        (plan?.id || 0),
        price?.stripe_id || '',
        defaultPaymentId ?? paymentId,
        (coupon?.type === 'coupon' ? coupon?.coupon.stripe_id : coupon?.promo?.code),
        need,
        signature?.contract?.toString() ?? null
      ).then((res) => {
        const { data } = res;
        if (data) {
          let object: any = {
            // Use the Stripe 'object' property on the
            // returned result to understand what object is returned.
            paymentMethodId: defaultPaymentId ?? paymentId,
            priceId: price?.stripe_id,
            paymentType: defaultPaymentType ?? paymentType
          };
          if (data.object === "invoice") {
            object.invoice = data;
          } else {
            object.subscription = data;
          }
          return object;
        }
      })
      .then((result) => {
      if (result.paymentType === 'card') {
        return handlePaymentThatRequiresCustomerAction(result);
      } else if (result.paymentType === 'sepa_debit') {
        return handlePaymentSEPAThatRequiresCustomerAction(result);
      } else {
        throw new Error('Unsupported payment type');
      }
    })
    .then(handleRequiresPaymentMethod)
    .then(onSubscriptionComplete)
    .catch(async (error) => {
      if (error?.message) {
        setPaymentError(error.message);
      }

      if (user?.uid && !authLoading) {
        const token = await user.getIdToken();
        mixpanelCheckout(
          user.uid,
          "failed",
          token,
          price?.stripe_id || '',
          JSON.stringify(error)
        );
      }

      if (error.type === "card_error") {
        if (error.decline_code === "card_not_supported") {
          setPaymentError(
            "Your card is not supported. Please use a different card"
          );
        }
      }
      setPaymentFailed(true);
    })
    .finally(() => {
      setLoading(false);
    });
  },[authLoading, coupon?.coupon?.stripe_id, coupon?.promo?.code, coupon?.type, handlePaymentSEPAThatRequiresCustomerAction, handlePaymentThatRequiresCustomerAction, handleRequiresPaymentMethod, need, onSubscriptionComplete, paymentId, paymentType, plan?.id, price?.stripe_id, signature?.contract, user]);
  
  const startElectronicSignature = useCallback(async (iframe = false) => {
    const billing = {
      firstName,
      lastName,
      city,
      zip,
      address
    };

    const errors = validateFields(billing, need);
    setFormErrors(null);
    if (errors) {
      setFormErrors(errors);
      return;
    }

    const params = {
      course: courseIdForSignature,
      total: totalPrice,
      giveup: giveUp,
      successURL: `${window.location.origin}/${slug}`,
      cancelURL: `${window.location.origin}/${slug}?canceled=true`,
      failURL: `${window.location.origin}/${slug}?fail=true`,
      billing: {
        city: city,
        country: typeof country === 'object' ? country.value : country,
        postal_code: zip,
        line1: address,
        user: {
          firstname: firstName ?? "-",
          lastname: lastName ?? "-",
        }
      },
      meta: {
        checkoutSlug: slug
      }
    };

    setLoading(true);
    await onPaymentStartStep1()
      .then(() => generateElectronicSignature(params))
      .then(({ data }) => {
        setSignature({ ...data, iframe: iframe });
        window.document.getElementById('top_payment_process')?.scrollIntoView();
      })
      .catch((error) => {
        console.error(error);
        setPaymentError(error.message);
        setPaymentFailed(true);
      })
      .finally(() => setLoading(false));
  }, [firstName, lastName, city, zip, address, need, validateFields, courseIdForSignature, totalPrice, giveUp, slug, country, onPaymentStartStep1]);


  const startElectronicSignatureStep1 = useCallback(async () => {
    const billing = {
      firstName,
      lastName,
      city,
      zip,
      address
    };

    const errors = validateBillingFields(billing);
    setFormErrors(null);
    if (errors) {
      setFormErrors(errors);
      return;
    }

    setLoading(true);
    return await onPaymentStartStep1()
      .catch((error) => {
        console.error(error);
        setPaymentError(error.message);
        setPaymentFailed(true);
      })
      .finally(() => {
        setLoading(false);
        window.document.getElementById('top_payment_process')?.scrollIntoView();
      });
  }, [firstName, lastName, city, zip, address, onPaymentStartStep1]);

  const startElectronicSignatureStep2 = useCallback(async (iframe = false) => {
    const billing = {
      firstName,
      lastName,
      city,
      zip,
      address
    };

    const errors = validateFields(billing, need);
    setFormErrors(null);
    if (errors) {
      setFormErrors(errors);
      return false;
    }

    const params = {
      course: courseIdForSignature,
      total: totalPrice,
      giveup: giveUp,
      successURL: `${window.location.origin}/${slug}`,
      cancelURL: `${window.location.origin}/${slug}?canceled=true`,
      failURL: `${window.location.origin}/${slug}?fail=true`,
      billing: {
        city: city,
        country: typeof country === 'object' ? country.value : country,
        postal_code: zip,
        line1: address,
        user: {
          firstname: firstName ?? "-",
          lastname: lastName ?? "-",
        }
      },
      meta: {
        checkoutSlug: slug
      }
    };

    setLoading(true);
    return await generateElectronicSignature(params)
      .then(({ data }) => {
        setSignature({ ...data, iframe: iframe });
        window.document.getElementById('top_payment_process')?.scrollIntoView();
        return true;
      })
      .catch((error) => {
        console.error(error);
        setPaymentError(error.message);
        setPaymentFailed(true);
        return false;
      })
      .finally(() => setLoading(false));
  }, [firstName, lastName, city, zip, address, need, validateFields, courseIdForSignature, totalPrice, giveUp, slug, country]);

  const onPaymentStart = useCallback(async () => {
    const billing = {
      firstName,
      lastName,
      city,
      zip,
      address
    };
    
    const errors = validateBillingFields(billing);
    setFormErrors(null);
    if (errors) {
      setFormErrors(errors);
      return;
    }

    setLoading(true);
    await onPaymentStartStep1()
      .then(async (data) => {
        if (data && data.paymentId && data.paymentType) {
          await onPaymentStartStep2(data.paymentId, data.paymentType);
        }
        return;
      })
      .catch(async (error) => {
        if (error?.message) {
          setPaymentError(error.message);
        }

        if (user?.uid && !authLoading) {
          const token = await user.getIdToken();
          mixpanelCheckout(
            user.uid,
            "failed",
            token,
            price?.stripe_id || '',
            JSON.stringify(error)
          );
        }

        if (error.type === "card_error") {
          if (error.decline_code === "card_not_supported") {
            setPaymentError(
              "Your card is not supported. Please use a different card"
            );
          }
        }
        setPaymentFailed(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [address, authLoading, city, firstName, lastName, onPaymentStartStep1, onPaymentStartStep2, price?.stripe_id, user, zip]);

  const contextValue: PaymentContextType = useMemo(() => ({
    loading: loading,
    setLoading: setLoading,
    onPaymentStart: onPaymentStart,
    startElectronicSignature: startElectronicSignature,
    startElectronicSignatureStep1: startElectronicSignatureStep1,
    startElectronicSignatureStep2: startElectronicSignatureStep2,
    onPaymentStartStep2: onPaymentStartStep2,
    onPaymentStartStep1: onPaymentStartStep1,
    totalPrice: totalPrice,
    paymentSuccess: paymentSuccess,
    setPaymentSuccess: setPaymentSuccess,
    formErrors,
    setFormErrors,
    paymentFailed: paymentFailed,
    setPaymentFailed: setPaymentFailed,
    paymentError: paymentError,
    setPaymentError: setPaymentError,
    firstName: firstName,
    setFirstName: setFirstName,
    lastName: lastName,
    setLastName: setLastName,
    company: company,
    setCompany: setCompany,
    address: address,
    setAddress: setAddress,
    zip: zip,
    setZip: setZip,
    city: city,
    setCity: setCity,
    country: country,
    setCountry: setCountry,
    paymentId: paymentId,
    setPaymentId: setPaymentId,
    paymentType: paymentType,
    setPaymentType: setPaymentType,
    giveUp: giveUp,
    setGiveUp: setGiveUp,
    showBillingInfo: showBillingInfo,
    setShowBillingInfo: setShowBillingInfo,
    loadingCoupon: loadingCoupon,
    setLoadingCoupon: setLoadingCoupon,
    signature: signature,
    setSignature: setSignature,
    nextStepPayment: nextStepPayment,
    setNextStepPayment: setNextStepPayment,
    plan: plan,
    price: price,
    setPrice: setPrice,
    coupon: coupon,
    setCoupon: setCoupon,
    need: need,
    setNeed: setNeed,
    reset: reset
  }), [loading, onPaymentStart, startElectronicSignature, startElectronicSignatureStep1, startElectronicSignatureStep2, onPaymentStartStep2, onPaymentStartStep1, totalPrice, paymentSuccess, formErrors, paymentFailed, paymentError, firstName, lastName, company, address, zip, city, country, paymentId, paymentType, giveUp, showBillingInfo, loadingCoupon, signature, nextStepPayment, plan, price, coupon, need, reset]);

  return (
    <>
      <PaymentContext.Provider value={contextValue}>
        {children}
      </PaymentContext.Provider>
    </>
  );
}