export enum GTMEvents {
  successfulSignup = "sign_up",
  login = "login",
  wsBroker = "ws_broker",
  rltBroker = "rlt_broker",
  pageView = "pageview",
  purchase = "purchase",
  checkout = "begin_checkout",
  paymentInfo = "add_payment_info",
}
