import { FC, useContext, useEffect } from "react";
import { Spinner } from "../../../core/Loading";
import Form from "./Form";
import { PaymentContext } from "../../../../provider/Payment";

const PayForm: FC<{ currentStep: number; setCurrentStep: (step: number) => void }> = ({ currentStep, setCurrentStep }) => {
  const { plan, price, setPrice } = useContext(PaymentContext);

  useEffect(() => {
    if (!price && plan?.prices?.[0]?.price) {
      setPrice(plan?.prices?.[0]?.price);
    }
  }, [plan, price, setPrice]);

  if (plan == null || price?.amount == null || price?.currency == null) {
    return <Spinner />;
  }

  return (
      <Form currentStep={currentStep} setCurrentStep={setCurrentStep} />
  );
};

export default PayForm;
