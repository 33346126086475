import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { PaymentContext } from "../../../../provider/Payment";

export const FormGiveUp = () => {
    const { t } = useTranslation();
    const { price, plan, giveUp, setGiveUp } = useContext(PaymentContext);

    const hasSignature = useMemo(() => plan?.signature?.enabled || false, [plan]);
    const hasNeedsForm = useMemo(() => (price?.product?.hasOwnProperty('has_checkout_questionnary') && price?.product?.has_checkout_questionnary) || false, [price]);

    return (
        <>
            {
                hasSignature && (
                    <>
                        {
                        hasNeedsForm && (
                            <div className="w-full h-[2px] bg-[#DEE2E6] mb-4 mt-6"></div>
                        )
                        }
                        
                        <p className="text-base font-semibold my-4">Droit de rétractation</p>
                        <div className="w-full">
                            <div className="flex flex-col gap-2">
                                <div className="flex items-center gap-4">
                                <input
                                    type="radio"
                                    name="giveUpConfirmation"
                                    value="yes"
                                    checked={giveUp}
                                    onChange={() => setGiveUp(true)}
                                    className="text-sm"
                                />
                                <label className="text-sm">{t("common:yes")}</label>
                                
                                </div>
                                <div className="flex items-center gap-4">
                                <input
                                    type="radio"
                                    name="giveUpConfirmation"
                                    value="no"
                                    checked={!giveUp}
                                    onChange={() => setGiveUp(false)}
                                    className="text-sm"
                                />
                                <label className="text-sm">{t("common:no")}</label>
                                
                                </div>
                            </div>
                            
                            <p className="w-full text-sm my-5 hidden">
                                Confirmez pour générer votre contrat de formation. Vous pourrez le consulter et signer électroniquement en toute simplicité.
                            </p>
                                
                        </div>
                    </>
                )
            }
        </>
    );
};