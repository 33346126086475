import { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { NeedType } from "../../../../utils/constants/need";
import { PaymentContext } from "../../../../provider/Payment";

export const NeedsForm = () => {
    const { t } = useTranslation();
    const { need, setNeed, formErrors } = useContext(PaymentContext);

    const handleChange = (field: keyof NeedType, value: string) => {
        setNeed({ ...need, [field]: value } as NeedType);
    };

    return (
        <>
            <p className="text-base font-semibold mb-4 max-w-[800px]">Greenbull Campus est un organisme de formation certifié Qualiopi. Dans le cadre de notre démarche qualité et pour vous garantir le meilleur suivi, nous vous prions de bien vouloir remplir l’intégralité des champs ci-dessous.</p>
            <div className="my-4">
                <label className="text-base my-2">
                    {t("needs.attentes.label")}
                </label>
                <input
                    placeholder={t("needs.attentes.placeholder")}
                    className="text-sm w-full h-[44px] bg-[#fbfbfb] px-3 border-[1px] rounded-md border-solid border-[#ccc] font-medium"
                    value={need?.attentes}
                    onChange={(e) => handleChange('attentes', e.target.value)}
                />
                {(formErrors?.attentes) && (
                    <p className="text-xs my-1 text-red-500">
                        {t("required", { item: formErrors?.attentes || "Ce champ est" })}
                    </p>
                )}
            </div>
            
            <div className="my-4">
                <label className="text-base my-2">
                    {t("needs.projet.label")}
                </label>
                <input
                    placeholder={t("needs.projet.placeholder")}
                    className="text-sm w-full h-[44px] bg-[#fbfbfb] px-3 border-[1px] rounded-md border-solid border-[#ccc] font-medium"
                    value={need?.projet}
                    onChange={(e) => handleChange('projet', e.target.value)}
                />
                {(formErrors?.projet) && (
                    <p className="text-xs my-1 text-red-500">
                        {t("required", { item: formErrors?.projet || "Ce champ est" })}
                    </p>
                )}
            </div>

            <div className="my-4">
                <label className="text-base my-2">
                    {t("needs.experience.label")}
                </label>
                <select className="text-sm w-full h-[44px] bg-[#fbfbfb] px-3 border-[1px] rounded-md border-solid border-[#ccc] font-medium"
                    value={need?.experience}
                    onChange={(e) => handleChange('experience', e.target.value)}
                >
                    <option value="" disabled selected>{t("needs.experience.placeholder")}</option>
                    {
                        ["Inférieur à 1 an", "Entre 1 et 5 ans", "+ de 5 ans"].map((e) => {
                            return (
                                <option key={e} value={e}> {e}</option>   
                            );
                        })
                    }
                </select>
                {(formErrors?.experience) && (
                    <p className="text-xs my-1 text-red-500">
                        {t("required", { item: formErrors?.experience || "Ce champ est" })}
                    </p>
                )}
            </div>

            <div className="my-4">
                <label className="text-base my-2">
                    {t("needs.situationpro.label")}
                </label>
                <input
                    placeholder={t("needs.situationpro.placeholder")}
                    className="text-sm w-full h-[44px] bg-[#fbfbfb] px-3 border-[1px] rounded-md border-solid border-[#ccc] font-medium"
                    value={need?.situationpro}
                    onChange={(e) => handleChange('situationpro', e.target.value)}
                />
                {(formErrors?.situationpro) && (
                    <p className="text-xs my-1 text-red-500">
                        {t("required", { item: formErrors?.situationpro || "Ce champ est" })}
                    </p>
                )}
            </div>

            <div className="my-4">
                <label className="text-base my-2">
                    {t("needs.poste.label")}
                </label>
                <select className="text-sm w-full h-[44px] bg-[#fbfbfb] px-3 border-[1px] rounded-md border-solid border-[#ccc] font-medium"
                    value={need?.poste}
                    onChange={(e) => handleChange('poste', e.target.value)}
                >
                    <option value="" disabled selected>{t("needs.poste.placeholder")}</option>
                    {
                        ["En poste", "En reconversion", "Retraité", "Demandeur d'emploi", "Étudiant"].map((e) => {
                            return (
                                <option key={e} value={e}> {e}</option>   
                            );
                        })
                    }
                </select>
                {(formErrors?.poste) && (
                    <p className="text-xs my-1 text-red-500">
                        {t("required", { item: formErrors?.poste || "Ce champ est" })}
                    </p>
                )}
            </div>

            <div className="my-4">
                <label className="text-base my-2">
                    {t("needs.amenagement.label")}
                </label>
                <select className="text-sm w-full h-[44px] bg-[#fbfbfb] px-3 border-[1px] rounded-md border-solid border-[#ccc] font-medium"
                    value={need?.amenagement}
                    onChange={(e) => handleChange('amenagement', e.target.value)}
                >
                    <option value="" disabled selected>{t("needs.amenagement.placeholder")}</option>
                    {
                        ['Oui', 'Non'].map((e) => {
                            return (
                                <option key={e} value={e}> {e}</option>   
                            );
                        })
                    }
                </select>
                {(formErrors?.poamenagementste) && (
                    <p className="text-xs my-1 text-red-500">
                        {t("required", { item: formErrors?.amenagement || "Ce champ est" })}
                    </p>
                )}
            </div>

            <div className="my-4">
                <label className="text-base my-2">
                    {t("needs.autres.label")}
                </label>
                <input
                    placeholder={t("needs.autres.placeholder")}
                    className="text-sm w-full h-[44px] bg-[#fbfbfb] px-3 border-[1px] rounded-md border-solid border-[#ccc] font-medium"
                    value={need?.autres}
                    onChange={(e) => handleChange('autres', e.target.value)}
                />
            </div>

        </>
    );
};