import { PaymentContext } from "../../../provider/Payment";
import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../core/Button";

interface LogoutProps {
    currentStep?: number;
    onSignOut: () => void;
}

export const Logout: FC<LogoutProps> = ({ currentStep, onSignOut }) => {
    const { reset } = useContext(PaymentContext);
    const { t } = useTranslation();

    return (
        <>
            {(currentStep || 1) >= 2 && (
                <Button onClick={() => { reset(); onSignOut(); }}>{t("logout")}</Button>
            )}
        </>
    );
};