import { useState } from 'react';

type UseModalReturnType = [boolean, () => void];

const useModal = (): UseModalReturnType => {
	const [isShowing, setIsShowing] = useState<boolean>(true);

	const toggle = () => {
		setIsShowing(!isShowing);
	};

	return [
		isShowing,
		toggle
	];
}

export default useModal;