import { Signature } from '../../../../types/plan.types';

interface IFrameProps {
  signature: Signature;
  onSuccess: () => void;
}

const Iframe = ({ signature, onSuccess }: IFrameProps) => {
  return (
        <iframe id="iframe-contract" src={signature.url} style={{ width: '100%', height: '100%', minHeight: '650px', border: 'none' }} title="Signature Document" onLoad={(event) => {
            try {
                window.document.getElementById('top_payment_process')?.scrollIntoView();
                window.addEventListener('message',function(message){
                    if (message.origin === 'https://app.universign.com') {
                        if (message.data && message.data.status === 'signed') {
                            onSuccess();
                        }
                    }
                });
            } catch (error) {
                console.error("--> Error accessing window message:", error);
            }
        }}>
        </iframe>
  );
};

export default Iframe;