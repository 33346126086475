import { FC, useContext } from "react";
import { PaymentContext } from "../../../provider/Payment";
import { useTranslation } from "react-i18next";
import Lottie from "lottie-react";
import { Col } from "../../core/Container";
import FailedLottie from "../../../assets/animations/paymentFailed.json";
import SuccessLottie from "../../../assets/animations/paymentSuccess.json";

export const PaymentErrors: FC<{ fullPage: boolean, setCurrentStep: (step: number) => void, children?: React.ReactNode }> = ({ fullPage, setCurrentStep, children }) => {
    const { paymentSuccess, paymentFailed, setPaymentFailed, paymentError, setPaymentError } = useContext(PaymentContext);
    const { t } = useTranslation();
    
    return (
        <>
            { paymentFailed ? (
                <div className={fullPage ? "max-w-[400px] mx-auto" : ""}>
                    <Lottie animationData={FailedLottie} loop={false} />
                    <p className="text-red-500 self-center font-medium text-xs text-center -mt-4">
                    {paymentError}
                    </p>
                    <button
                    className="w-full h-[44px] green-gradient-button-bg mt-10 rounded-xl"
                    onClick={() => {
                        setPaymentFailed(false);
                        setCurrentStep(2);
                        setPaymentError(t("somethingWrong"));
                    }}
                    >
                    {t("tryAgain")}
                    </button>
                </div>
            ) : paymentSuccess ? (
                <div className={fullPage ? "max-w-[400px] mx-auto" : ""}>
                    <Lottie animationData={SuccessLottie} loop={false} />
                    <p className="text-green-500 self-center font-medium text-sm text-center -mt-4">
                    {t("paymentSuccess")}
                    </p>
                    <a href="https://greenbull-campus.fr/dashboard/">
                    <Col className="h-[44px] text-white items-center mt-10 justify-center green-gradient-button-bg rounded-xl">
                        {t("goToDashboard")}
                    </Col>
                    </a>
                </div>
            ) : (
                <>{children}</>
            )
            }
        </>
    );
};