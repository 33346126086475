import axios from "axios";
import { auth } from "../../firebase/auth/auth";
import { couponType } from "../../../utils/constants/plans";
import { getCookie } from "../../../utils/helpers";

export const getCheckoutDetails = async (checkoutSlug: string) => {
    return await axios.get(`${process.env.REACT_APP_SHOP_API_URL}/checkouts/slug/${checkoutSlug}`, {
        headers: {
            'Content-Type': 'application/json',
        }
    });
};


export const applyCoupon = async ({ coupon, productId, userToken, currency }: { coupon: string, productId: string | number, userToken: string, currency: string }) => {
    return await axios.get<couponType>(`${process.env.REACT_APP_SHOP_API_URL}/coupons/${coupon}/${currency}?products[]=${productId}`, {
        headers: {
            Authorization: 'Bearer firebase ' + userToken,
            'Content-Type': 'application/json',
        }
    });
};


export const getPaymentIntent = (subject: any) => {
    if (subject.object === 'invoice') {
        return subject.payment_intent
    }
    return subject.latest_invoice.payment_intent
}

export const createSubscription = async (
    checkoutId: number,
    priceId: string,
    paymentMethodId: string,
    coupon?: string,
    deal?: any,
    transactionId?: string|null
) => {
    const cookie = getCookie("PAPVisitorId");
    const body: any = {
        paymentMethodId,
        priceId,
        checkout_id: checkoutId,
        visitor: null,
        affiliate: null,
        campaign_id: null,
        fromWallet: false,
        bumps: [],
        transactionId
    }

    if (coupon) {
        body.coupon = coupon;
    }

    if (cookie) {
        body.visitor = cookie;
    }

    if (deal) {
         body.deal = deal;
    }

    const userAuth: any = auth.currentUser
    const userToken: string = await userAuth?.getIdToken();

    return await axios.post(`${process.env.REACT_APP_SHOP_API_URL}/order`, body, {
        headers: {
            Authorization: 'Bearer firebase ' + userToken,
            'Content-Type': 'application/json',
        }
    });
};

export const getBillingInfo = async (currency: string) => {
    const userAuth: any = auth.currentUser
    const userToken: string = await userAuth?.getIdToken();

    return await axios(`${process.env.REACT_APP_SHOP_API_URL}/billinginformations?currency=${currency}`, {
        headers: {
            Authorization: 'Bearer firebase ' + userToken,
            'Content-Type': 'application/json',
        }
    });
}

export const generateElectronicSignature = async (body: any) => {
    const userAuth: any = auth.currentUser
    const userToken: string = await userAuth?.getIdToken();

    return await axios.post(`${process.env.REACT_APP_SHOP_API_URL}/contract/generate`, body, {
        headers: {
            Authorization: 'Bearer firebase ' + userToken,
            'Content-Type': 'application/json',
        }
    });
}