import { Signature as SignatureType } from "../../../../types/plan.types";
import useModal from '../../../../hooks/useModal';
import Modal from '../../../core/Modal';
import Iframe from './Iframe';

interface PropsOptions {
    signature: SignatureType;
    onSuccess: () => void;
}

export const Signature = ({ signature, onSuccess }: PropsOptions) => {
    const [isShowingModal, toggleModal] = useModal();

    return (
        <>
            {
                signature.iframe ?
                ( 
                    <Modal show={isShowingModal} onCloseButtonClick={toggleModal} >
                        <Iframe signature={signature} onSuccess={onSuccess} />
                    </Modal> 
                )
                :
                (
                    <Iframe signature={signature} onSuccess={onSuccess} />
                )
            }
        </>
    );
}