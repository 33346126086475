import { PaymentContext } from "../../../provider/Payment";
import { FC, useCallback, useContext } from "react";
import { PlanTypes, Signature as SignatureType } from "../../../types/plan.types";
import { Col, Row } from "../../core/Container";
import { Signature } from "./Signature/Signature";

interface LoaderColProps {
  text: string;
}

export const LoaderCol: FC<LoaderColProps> = ({ text }) => {
    const { loading } = useContext(PaymentContext);
    return (
        <>
            {
                loading && (
                <div style={{
                    position: 'absolute',
                    backgroundColor: '#8c8c8c82',
                    zIndex: 999,
                    width: '100%',
                    height: '100%',
                    left: 0,
                    top: 0,
                    borderRadius: '0.5rem',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <div className="loading-main-button" />
                        <p className="m-2 text-white">{text}</p>
                </div>
                )     
            }
        </>
    );
};