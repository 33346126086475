import { FC, useContext, useMemo } from "react";
import { PaymentContext } from "../../../provider/Payment";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../services/firebase/auth/auth";
import { Spinner } from "../../core/Loading";

export const ButtonStep3: FC<{ currentStep: number; setCurrentStep: (step: number) => void }> = ({ currentStep, setCurrentStep }) => {
    const { signature, loading, startElectronicSignatureStep2, need } = useContext(PaymentContext);
    const stripe = useStripe();
    const elements = useElements();
    const [user, authLoading] = useAuthState(auth);

    const hasErrors = useMemo(() => {
        if (need) {
            if (!need.attentes.trim()
                || !need.projet.trim()
                || !need.experience.trim()
                || !need.situationpro.trim()
                || !need.poste.trim()
                || !need.amenagement.trim())
                return true;
            return false;
        }
    }, [need]);
    
    return (
        <>
            <button
                className={`w-[300px] h-[50px] rounded-xl ${hasErrors ? "bg-gray-400" : "green-gradient-button-bg"} font-medium my-8 text-white`}
                disabled={
                hasErrors || loading || !stripe || !elements || authLoading || !user?.uid
                }
                onClick={async () => {
                    if (await startElectronicSignatureStep2(false))
                        setCurrentStep(4);
                }}
            >
                {loading || (signature && !signature.signed) ? <Spinner /> : "Confirmer"}
            </button>
        </>
    );
};